
.student-home-recommend {
    position: relative;
    .recommend-icon {
        position: absolute; left: 0; top: 0;
        width: 26px; height: 26px;
        background-color: #ff5552;
        clip-path: polygon(0 0, 100% 0, 0 100%);
        border-radius: 4px 0 0 0;
        &::before {
            display: block;
            content: "推荐";
            font-size: 12px; color: #fff;
            transform: rotate(-45deg) scale(0.7) translate(-5px, -3px);
        }
    }
}
